import React from "react"
import Layout from "../components/Layout"

import Kapil from "../images/kapil.jpg"
import Paul from "../images/paul.jpg"

export default function About() {
  return (
    <Layout>
      <div className="w-screen bg-primary py-40">
        <h1 className="text-7xl text-white text-center uppercase tracking-wider font-heading">About</h1>
        <div className="text-white text-xl font-body mt-12 text-justify md:text-center container mx-auto px-8 leading-relaxed">
          <p>
          Calabasas Films began as the brainchild of Calabasas, California resident and film/TV producer Kapil Mahendra in 2008. After producing several programs for CNN, The Tennis Channel, Sony Pictures and more, Mahendra joined forces with Manimal Group CEO and founder Paul Beahan to create a larger pallet of culturally diverse entertainment with focus on streaming content for the 21st century. As of 2020, the pair have produced a slew of food and travel series, international documentary series and beyond.
          </p>
        </div>
      </div>
      {/* <div className="transform skew-y-5 -translate-y-20 h-40 bg-primary w-screen slant:hidden"></div> */}

      <div className="container mx-auto my-32">
        <h2 className="uppercase text-center text-5xl text-gray-800 font-heading">
          The Producers
        </h2>
        <div className="flex flex-col mt-32 space-y-28">

          <div className="flex flex-col space-y-10 md:space-y-0 md:space-x-24 justify-center md:flex-row">
            <div className="md:w-80">
              <div className="rounded-full h-80 w-80 shadow-lg m-auto"
                style={{
                  backgroundImage: `url(${Kapil})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                }}></div>
            </div>
            <div className="flex flex-col space-y-6 md:w-6/12">
              <h3 className="text-5xl font-heading text-center md:text-left">Kapil Mahendra</h3>
              <p className="font-body text-justify md:text-left text-lg px-6 md:px-0">Calabasas native, Kapil Mahendra is a seasoned TV producer and filmmaker, entrepreneur and philanthropist in his own right. As a youth, he was a super-star athlete attaining some of the top awards for his sportsmanship as well as recognition for his passion for philanthropy.  Mahendra has produced content for CNN, Discovery, A&E and Sony Pictures and televised events with Jerry Seinfeld, The Beach Boys, Alanis Morissette and Matthew Perry. Mahendra started Calabasas Films in 2010 and helped create CNN’s Dimensions with a heavy roster of celebrated guests including Cindy Crawford, Hugh Hefner, Pierce Brosnan, Martin Luther King III, Michael Douglas and others.
              </p>
            </div>
          </div>

          <div className="flex flex-col space-y-10 md:space-y-0 md:space-x-24 justify-center md:flex-row">
            <div className="md:w-80">
              <div className="rounded-full h-80 w-80 shadow-lg m-auto"
                style={{
                  backgroundImage: `url(${Paul})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                }}></div>
            </div>
            <div className="flex flex-col space-y-6 md:w-6/12">
              <h3 className="text-5xl text-center md:text-left font-heading">Paul Beahan</h3>
              <p className="font-body text-justify md:text-left text-lg px-6 md:px-0">Paul Beahan has a rich history as a film, TV and music producer for the last 15 years. As a youth, Beahan grew up between San Diego and Los Angeles honing skills in the art, fashion and independent music world with the founding of Manimal Group (pka Manimal Vinyl Records) in 2006 after several years working as a freelance fashion editor. With Manimal, Beahan released debut albums from indie darlings Warpaint and Bat for Lashes and eventually collaborating on exclusive projects involving David Bowie, Yoko Ono, The estate of John Lennon, Duran Duran, The Cure, Moby, Carla Bruni and too many more to name. Eventually pivoting from a record label, Manimal began building a music library that expanded to film and television distribution with a focus on the world of streaming content.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </Layout>
  )
}

const Card = ({ name, description, image }) => (
  <div className="text-center flex flex-col space-y-6 lg:p-14">
    <div 
      className="rounded-full h-72 w-72 mx-auto shadow-lg"
      style={{
        backgroundImage: `url(${image==="kapil" ? Kapil : Paul})`,
        backgroundSize: '100%', backgroundRepeat: "no-repeat"
      }}
    ></div>
    <h3 className="text-4xl">{name}</h3>
    <p className="font-body text-center w-full text-lg tracking-wide">
      {description}
    </p>
  </div>
)


{/* <div className="flex flex-col space-y-12 xl:flex-row xl:space-x-12 xl:space-y-0 w-8/12 mx-auto py-10">
          <Card
            name="Kapil Mahendra" image="kapil"
            description="Calabasas native, Kapil Mahendra is a seasoned TV producer, entrepreneur and philanthropist in his own right.
            As a youth, he was a super-star athlete attaining some of the top awards for his sportsmanship as well as recognition for his passion for philanthropy which ultimately led him to befriend tennis legend, actor and fellow philanthropist Vijay Amritraj, leading to Mahendra heading Vijay’s foundation which focuses on women’s and children’s causes in India. 
            The foundation with Mahendra at the helm, has hosted televised events with Jerry Seinfeld, The Beach Boys, Alanis Morissette, Matthew Perry and tennis legends Andre Agassi, Steffi Graf and of course Vijay himself.
            Mahendra started Calabasas Films in 2010 and helped create CNN’s Dimensions with Vijay and a heavy roster of celebrated guests ranging from a Hugh Hefner, Pierce Brosnan, Martin Luther King III, Michael Douglas and others. 
            "
          />
          <Card
            name="Paul Beahan" image="paul"
            description="Paul Beahan has a rich history as a film, TV and music producer for the last 15 years. As a youth, Beahan grew up between San Diego and Los Angeles honing skills in the art, fashion and independent music world with the founding of Manimal Group (pka Manimal Vinyl Records) in 2006 after several years working as a freelance fashion editor.
            With Manimal, Beahan released debut albums from indie darlings Warpaint and Bat for Lashes and eventually collaborating on exclusive projects involving David Bowie, Yoko Ono, The estate of John Lennon, Duran Duran, The Cure, Moby, Carla Bruni and too many more to name. Eventually pivoting from a record label, Manimal began building a music library that expanded to film and television distribution with a focus on the world of streaming content.
            "
          />
        </div> */}